<template>
    <div>
        <div v-if="trivia_doc">
            <a :href="trivia_doc" class="button" target="_blank">Download Word Document</a>
        </div>
        <div v-if="trivia_pdf">
            <a :href="trivia_pdf" class="button" target="_blank">Download PDF</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TriviaAndFacts',
        props: ['trivia_doc', 'trivia_pdf'],
    };
</script>
