var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.trivia_doc
      ? _c("div", [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { href: _vm.trivia_doc, target: "_blank" }
            },
            [_vm._v("Download Word Document")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.trivia_pdf
      ? _c("div", [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { href: _vm.trivia_pdf, target: "_blank" }
            },
            [_vm._v("Download PDF")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }