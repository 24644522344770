<template>
    <article class="search-result">
        <div class="search-result__image">
            <a v-if="item.photo" key="item-photo" :href="item.url">
                <img :src="item.photo" />
            </a>
            <img v-else key="item-photo" src="/images/shell/layout/no-listing390x215.jpg">
        </div>
        <div class="search-result__details">
            <h2 class="search-result__name"><a :href="item.url" v-html="item.title"></a></h2>
            <div v-if="item.deal_name" class="search-result__deal-name"><a :href="item.url" v-html="item.deal_name"></a></div>
            <div v-if="item.event_date" class="search-result__event-date" v-html="item.event_date"></div>
            <div v-if="item.event_schedule" class="search-result__event-date" v-html="item.event_schedule"></div>
            <address v-if="item.street && item.city  && item.state  && item.zip">
                <div class="result-block__address">{{ item.street }}<br />{{ item.city }}, {{ item.state }} {{ item.zip }}</div>
            </address>
            <p v-html="item.intro"></p>
            <a :href="item.url" class="button button-primary button-shadow">Learn More</a>
        </div>
    </article>
</template>

<script>
    export default {
        name: 'ResultBlock',
        props: {
            item: {
                type: Object,
                default: function() {
                    return [];
                }
            },
        },
        data() {
            return {
                type: 'listings',
            };
        },
    };
</script>