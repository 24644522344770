const defer = function defer() {
    let state = false; // Resolved or not

    let callbacks = [];

    const resolve = function resolve(val) {
        if (state) {
            return;
        }

        state = true;

        for (let i = 0, len = callbacks.length; i < len; i++) {
            callbacks[i](val);
        }
    };

    const then = function then(cb) {
        if (!state) {
            callbacks.push(cb);
            return;
        }

        cb();
    };

    const deferred = {
        resolved: function() {
            return state;
        },
        resolve: resolve,
        promise: {
            then: then
        }
    };
    return deferred;
};

const ownProp = Object.prototype.hasOwnProperty;

function createRecaptcha() {
    const deferred = defer();
    return {
        notify: function() {
            deferred.resolve();
        },
        wait: function() {
            return deferred.promise;
        },
        render: function(ele, options, cb) {
            this.wait().then(function() {
                cb(window.grecaptcha.render(ele, options));
            });
        },
        reset: function(widgetId) {
            if (typeof widgetId === 'undefined') {
                return false;
            }

            this.assertLoaded();
            this.wait().then(function() {
                return window.grecaptcha.reset(widgetId);
            });
        },
        execute: function(widgetId) {
            if (typeof widgetId === 'undefined') {
                return false;
            }

            this.assertLoaded();
            this.wait().then(function() {
                return window.grecaptcha.execute(widgetId);
            });
        },
        checkRecaptchaLoad: function() {
            if (ownProp.call(window, 'grecaptcha') && ownProp.call(window.grecaptcha, 'render')) {
                this.notify();
            }
        },
        assertLoaded: function() {
            if (!deferred.resolved()) {
                alert('ReCAPTCHA has not been loaded');
                throw new Error('ReCAPTCHA has not been loaded');
            }
        }
    };
}

const recaptcha = createRecaptcha();

window.vueRecaptchaRef = null;

if (typeof window !== 'undefined') {
    window.vueRecaptchaApiLoaded = function() {
        vueRecaptchaRef.emitOnLoad();
        recaptcha.notify();
    };
}

const VueRecaptcha = {
    name: 'VueRecaptcha',
    props: {
        sitekey: {
            type: String,
            required: true
        },
        theme: {
            type: String
        },
        badge: {
            type: String
        },
        type: {
            type: String
        },
        size: {
            type: String
        },
        tabindex: {
            type: String
        },
        loadRecaptchaScript: {
            type: Boolean,
            'default': false
        },
        recaptchaOnloadFunction: {
            type: String,
            'default': 'vueRecaptchaApiLoaded'
        },
        recaptchaScriptId: {
            type: String,
            'default': '__RECAPTCHA_SCRIPT'
        },
        recaptchaHost: {
            type: String,
            'default': 'www.google.com'
        },
        language: {
            type: String,
            'default': ''
        }
    },
    data: function() {
        return {
            failureMessage: 'The captcha failed to load.\n\nPlease disable your ad blockers, check your browser\'s security settings, and refresh your page to try again.\n\nIf you are using Internet Explorer, please use Microsoft Edge and try again.'
        };
    },
    beforeMount: function() {
        // Set correct reference for VueRecaptcha
        window.vueRecaptchaRef = this;
        if (this.loadRecaptchaScript) {
            if (!document.getElementById(this.recaptchaScriptId)) {
                // Note: vueRecaptchaApiLoaded load callback name is per the latest documentation
                const script = document.createElement('script');
                script.id = this.recaptchaScriptId;
                script.src = 'https://' + this.recaptchaHost + '/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=' + this.language;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
            }
        }
    },
    mounted: function() {
        // Set error message in case recaptcha doesn't load
        this.$el.innerHTML = '<div class="error-box"><strong>The captcha failed to load.</strong><br>Please disable your ad blockers, check your browser\'s security settings, and refresh your page to try again.<br>If you are using Internet Explorer, please use Microsoft Edge and try again.</div>';
    },
    methods: {
        reset: function() {
            if (recaptcha.reset(this.$widgetId) === false) {
                alert(this.failureMessage);
                return false;
            }

            return true;
        },
        execute: function() {
            if (recaptcha.execute(this.$widgetId) === false) {
                alert(this.failureMessage);
                return false;
            }

            return true;
        },
        emitVerify: function(response) {
            this.$emit('verify', response);
        },
        emitExpired: function() {
            this.$emit('expired');
        },
        emitError: function() {
            this.$emit('error');
        },
        emitOnLoad: function() {
            this.$emit('onload');

            recaptcha.checkRecaptchaLoad();

            let opts = {
                ...this.$props,
                callback: this.emitVerify,
                'expired-callback': this.emitExpired,
                'error-callback': this.emitError
            };

            // Clear content before rendering reCaptcha
            this.$el.innerHTML = '';
            recaptcha.render(this.$el, opts, (id) => {
                this.$widgetId = id;

                this.$emit('render', id);
            });
        }
    },
    render: function(h) {
        return h('div', {}, this.$slots['default']);
    }
};

export default VueRecaptcha;
