var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-modal",
      class: { "v-modal--active": _vm.is_active },
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.close.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "v-modal__content-wrap" }, [
        _c(
          "div",
          { staticClass: "v-modal__content" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "v-modal__close button-close",
                attrs: { type: "button" },
                on: { click: _vm.close }
              },
              [_vm._v("Close")]
            )
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }