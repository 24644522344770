var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.curated_type) + ": " + _vm._s(_vm.items.length))
      ]),
      _vm._v(" "),
      _vm.search_items.length > 5
        ? _c("div", { staticClass: "curated-search-filter" }, [
            _c("div", { staticClass: "curated-search-filter__sort" }, [
              _c(
                "label",
                {
                  staticClass: "curated-search-filter__sort-label",
                  attrs: { for: "curateSort" }
                },
                [_vm._v("Sort")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.current_sort,
                      expression: "current_sort"
                    }
                  ],
                  staticClass: "curated-search-filter__sort-list",
                  attrs: { id: "curateSort" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.current_sort = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.sortSelected($event)
                      }
                    ]
                  }
                },
                _vm._l(_vm.sort_types, function(sortType) {
                  return _c("option", {
                    domProps: { value: sortType, innerHTML: _vm._s(sortType) }
                  })
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "curated-search-filter__search" }, [
              _c("label", {
                staticClass: "curated-search-filter__search-label",
                attrs: { for: "query" },
                domProps: { innerHTML: _vm._s("Search " + _vm.curated_type) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "curated-search-filter__search-fields" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.query,
                        expression: "query"
                      }
                    ],
                    staticClass: "curated-search-filter__search-field",
                    attrs: {
                      id: "query",
                      placeholder: "Search " + _vm.curated_type,
                      maxlength: "100",
                      name: "query",
                      type: "search"
                    },
                    domProps: { value: _vm.query },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.search.apply(null, arguments)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.query = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "button is-primary curated-search-filter__submit",
                      attrs: { type: "button" },
                      on: { click: _vm.search }
                    },
                    [_vm._m(0), _vm._v(" "), _c("span", [_vm._v("Search")])]
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          paginated_items: _vm.items,
          per_page: _vm.items_per_page,
          starting_page: _vm.current_page
        },
        on: {
          "update-pages": _vm.updateCurrentPage,
          "update-paged-items": _vm.getPaginatedItems
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.pagedItems, function(item) {
        return _c("result-block", { key: item.id, attrs: { item: item } })
      }),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c("h3", [
            _vm._v("No results found. Please change your search and try again.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.items.length > _vm.items_per_page
        ? _c("pagination", {
            attrs: {
              paginated_items: _vm.items,
              per_page: _vm.items_per_page,
              starting_page: _vm.current_page
            },
            on: { "update-pages": _vm.updateCurrentPage }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }