<template>
    <div>
        <label :class="{'required': required}" class="form-item__label">{{ title ? title : 'Click to upload or drop your images here' }}</label>
        <div class="image-upload__max">
            <p>Maximum files: {{ max }}</p>
            <p v-if="dimensions">Required Dimensions: {{ dimensions }}</p>
        </div>
        <div class="image-upload__container" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave" @drop.prevent="drop($event)">
            <div v-show="dropping === 1" class="image-upload__drop"></div>
            <!-- Error Message -->
            <div v-show="error" class="image-upload__error">
                {{ error }}
            </div>

            <!-- To inform user how to upload image -->
            <div v-show="images.length === 0" class="image-upload__before">
                <input ref="uploadInput" :accept="allowedFileTypes" :name="inputName" multiple tabindex="-1" type="file" />
                <input ref="uploadInputBuffer" :accept="allowedFileTypes" multiple type="file" @change="imageInputChanged" />
                <svg class="image-upload__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title>Upload Image</title>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <circle cx="18.5" cy="16.5" r="5" />
                                </g>
                                <polyline points="16.5 15.5 18.5 13.5 20.5 15.5" />
                                <line x1="18.5" x2="18.5" y1="13.5" y2="19.5" />
                            </g>
                            <g>
                                <polyline points="0.6 15.42 6 10.02 8.98 13" />
                                <polyline points="17.16 11.68 12.5 7.02 7.77 11.79" />
                                <circle cx="8" cy="6.02" r="1.5" />
                                <path d="M19.5,11.6V4A1.5,1.5,0,0,0,18,2.5H2A1.5,1.5,0,0,0,.5,4V15A1.5,1.5,0,0,0,2,16.5H13.5" />
                            </g>
                        </g>
                    </g>
                </svg>
                <p>Drag files or click here!</p>
            </div>
            <div v-show="images.length > 0" class="image-upload__preview">
                <button class="image-upload__clear" type="button" @click="reset">{{ clearAllText ? clearAllText : 'Clear All' }}</button>
                <div v-for="(image, i) in images" :key="i" class="image-upload__image-holder">
                    <img :alt="image.name" :src="image.data" :title="image.name" />
                    <div v-if="++i === images.length && images.length < max" class="image-upload__plus" @click="append">+</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImageUploader',
        props: {
            max: {
                type: Number,
                default: 1
            },
            maxSize: {
                type: Number,
                default: 0
            },
            allowedFileTypes: {
                type: String,
                default: 'image/png,image/jpeg,image/tiff,image/webp'
            },
            title: String,
            maxUploadsError: String,
            maxSizeError: {
                type: String,
                default: 'Files must be smaller than posted size'
            },
            fileTypeError: {
                type: String,
                default: 'Unsupported file type'
            },
            dimensions: String,
            clearAllText: String,
            inputName: String,
            required: Boolean
        },
        data() {
            return {
                error: '',
                files: [],
                dropping: 0,
                images: [],
                pdf_placeholder_blob: null
            };
        },
        methods: {
            append() {
                this.$refs.uploadInputBuffer.click();
            },
            dragOver() {
                this.dropping = 1;
            },
            dragLeave() {
                this.dropping = 0;
            },
            drop(event) {
                if (this.appendFilesToInput(event.dataTransfer.files)) {
                    this.previewImages();
                }
                this.dropping = 0;
            },
            imageInputChanged(event) {
                if (this.appendFilesToInput(event.currentTarget.files)) {
                    this.previewImages();
                }

                this.$refs.uploadInputBuffer.value = null;
            },
            appendFilesToInput(files) {
                let dt = new DataTransfer();

                for (let file of this.$refs.uploadInput.files) {
                    dt.items.add(file);
                }

                this.error = '';

                if (files) {
                    let types = this.allowedFileTypes.split(',');
                    // If only 1 file was uploaded, actually show errors
                    let showError = files.length === 1;
                    for (let file of files) {
                        if (dt.items.length + 1 > this.max) {
                            if (showError) {
                                this.error = this.maxUploadsError ? this.maxUploadsError : `Maximum files: ${this.max}`;
                                return false;
                            }
                            // exceeds max file count, stop processing files
                            break;
                        }
                        if (types.indexOf(file.type) < 0) {
                            if (showError) {
                                this.error = this.fileTypeError;
                                return false;
                            }
                            // File type not supported, skip
                            continue;
                        }
                        if (this.maxSize > 0 && file.size > this.maxSize) {
                            this.error = this.maxSizeError;
                            // exceeds max filesize, skip file
                            continue;
                        }
                        dt.items.add(file);
                        this.files.push(file);
                    }
                }

                this.$refs.uploadInput.files = dt.files;
                this.$emit('changed', this.files);
                return true;
            },
            previewImages() {
                let readers = [];
                if (!this.files.length) {
                    return;
                }
                for (let i = 0; i < this.files.length; i++) {
                    readers.push(this.readAsDataURL(this.files[i]));
                }
                Promise.all(readers).then((values) => {
                    this.images = values;
                });
            },
            readAsDataURL(file) {
                let vm = this;
                return new Promise(async function (resolve, reject) {
                    let fr = new FileReader();
                    fr.onload = function () {
                        resolve({
                            'name': file.name,
                            'data': fr.result
                        });
                    };
                    fr.onerror = function () {
                        reject(fr);
                    };

                    if (file.type.indexOf('pdf') !== -1) {
                        if (vm.pdf_placeholder_blob === null) {
                            const placeholderData = await fetch('/images/shell/layout/pdf-placeholder.png');
                            const placeholderBlob = await placeholderData.blob();
                            vm.pdf_placeholder_blob = placeholderBlob;
                        }

                        file = new File([vm.pdf_placeholder_blob], file.name);
                    }

                    fr.readAsDataURL(file);
                });
            },
            reset() {
                this.error = '';
                this.images = [];
                this.files = [];
                this.$refs.uploadInput.value = null;
                this.$refs.uploadInputBuffer.value = null;
                this.$emit('changed', this.files);
            }
        }
    };
</script>