<template>
    <div>
        <select id="" v-model="toolkit" name="">
            <option value="">Select a Toolkit</option>
            <option v-for="toolkit in toolkits" :value="toolkit">{{ toolkit.name }}</option>
        </select>
        <div v-if="toolkit !== ''">
            <h3>{{ toolkit.name }}</h3>
            <div v-if="toolkit.itinerary_url">
                <a :href="toolkit.itinerary_url" class="button" target="_blank">Download Itinerary</a>
            </div>
            <div v-if="toolkit.escort_notes_url">
                <a :href="toolkit.escort_notes_url" class="button" target="_blank">Download Escort Notes</a>
            </div>
            <div v-if="toolkit.power_point_url">
                <a :href="toolkit.power_point_url" class="button" target="_blank">Download Power Point</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItineraryToolkits',
        props: ['toolkits'],
        data() {
            return {
                toolkit: '',
            };
        },
    };
</script>
