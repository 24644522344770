var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inspiration,
            expression: "inspiration"
          }
        ],
        attrs: { id: "", name: "" },
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.inspiration = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v("Select an Inspiration")
        ]),
        _vm._v(" "),
        _vm._l(_vm.inspirations, function(inspiration) {
          return _c("option", { domProps: { value: inspiration } }, [
            _vm._v(_vm._s(inspiration.name))
          ])
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm.inspiration !== ""
      ? _c("div", [
          _c("h3", [_vm._v(_vm._s(_vm.inspiration.name))]),
          _vm._v(" "),
          _vm.inspiration.document
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "button",
                    attrs: { href: _vm.inspiration.document, target: "_blank" }
                  },
                  [_vm._v("Download Inspiration")]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }