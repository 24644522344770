<template>
    <div id="pager" class="pager">
        <nav v-if="pages.length > 1" class="pagination">
            <a v-if="current_page !== 1" class="pagination__previous" @click="previousPage">&larr;</a>
            <a v-for="page in pages" :class="{pagination__active: page === current_page}" @click="setPage(page)" v-html="page"></a>
            <a v-if="current_page !== maxPages" class="pagination__next" @click="nextPage">&rarr;</a>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            paginated_items: {
                type: Array,
                default: function() {
                    return [];
                }
            },
            starting_page: {
                type: Number,
                default: function() {
                    return 1;
                }
            },
            per_page: {
                type: Number
            }
        },
        data() {
            return {
                current_page: 1
            };
        },
        mounted: function() {
            const vm = this;
            this.current_page = this.starting_page;
            this.$nextTick(function() {
                vm.$emit('update-paged-items', vm.filteredItems);
            });
        },
        computed: {
            pages: function() {
                let pageArr = [];
                let maxPagesShown = 6;
                // always show first page
                pageArr.push(1);
                let i;
                if (this.current_page <= (maxPagesShown / 2) || maxPagesShown > this.maxPages) {
                    // make pagination
                    for (i = 2; i < this.maxPages; i++) {
                        if (i <= maxPagesShown) {
                            pageArr.push(i);
                        }
                    }
                } else {
                    let startPage = this.current_page;
                    // Show page numbers from first to maxPagesShown
                    if (this.current_page > Math.ceil(maxPagesShown / 2)) {
                        startPage = (this.current_page - Math.ceil(maxPagesShown / 2)) + 1;
                    }
                    // Show page numbers from last page minus maxPagesShown
                    if ((this.maxPages - this.current_page) <= Math.ceil(maxPagesShown / 2)) {
                        startPage = this.maxPages - maxPagesShown > 1 ? this.maxPages - maxPagesShown : 2;
                    }
                    // Loop through pages show all from startPage to maxPages
                    if (this.current_page <= this.maxPages) {
                        for (i = startPage; i < this.maxPages; i++) {
                            if (i <= (maxPagesShown + startPage) - 1) {
                                pageArr.push(i);
                            }
                        }
                    }
                }
                // If there's more than one page, add last page
                if (this.maxPages > 1) {
                    pageArr.push(this.maxPages);
                }

                return pageArr;
            },
            maxPages: function() {
                return Math.ceil(this.paginated_items.length / this.per_page);
            },
            slice: function() {
                return (this.current_page - 1) * this.per_page;
            },
            filteredItems: function() {
                return this.paginated_items.slice(this.slice, this.per_page + this.slice);
            },
        },
        watch: {
            current_page: function() {
                this.$emit('update-pages', this.current_page);
                this.$emit('update-paged-items', this.filteredItems);
            },
            starting_page: function() {
                this.current_page = this.starting_page;
            },
            filteredItems: function() {
                this.$emit('update-paged-items', this.filteredItems);
            }
        },
        methods: {
            setPage: function(pageNumber) {
                this.current_page = pageNumber;
            },
            firstPage: function() {
                this.current_page = 1;
            },
            lastPage: function() {
                this.current_page = this.maxPages;
            },
            previousPage: function() {
                if (this.current_page !== 1) {
                    this.current_page += -1;
                }
            },
            nextPage: function() {
                if (this.current_page !== this.maxPages) {
                    this.current_page += 1;
                }
            },
        }
    };
</script>