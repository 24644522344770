<template>
    <div>
        <select id="" v-model="inspiration" name="">
            <option value="">Select an Inspiration</option>
            <option v-for="inspiration in inspirations" :value="inspiration">{{ inspiration.name }}</option>
        </select>
        <div v-if="inspiration !== ''">
            <h3>{{ inspiration.name }}</h3>
            <div v-if="inspiration.document">
                <a :href="inspiration.document" class="button" target="_blank">Download Inspiration</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MultiStateInspirations',
        props: ['inspirations'],
        data() {
            return {
                inspiration: '',
            };
        },
    };
</script>
