<template>
    <div :class="{'v-modal--active': is_active}" class="v-modal" @click.self="close">
        <div class="v-modal__content-wrap">
            <div class="v-modal__content">
                <slot></slot>
                <button class="v-modal__close button-close" type="button" @click="close">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            active: Boolean,
        },
        data() {
            return {
                is_active: this.active || false
            };
        },
        created() {
            if (typeof window !== 'undefined') {
                document.addEventListener('keyup', this.keyPress);
            }
        },
        beforeDestroy() {
            if (typeof window !== 'undefined') {
                document.removeEventListener('keyup', this.keyPress);
                // reset scroll
                document.documentElement.classList.remove('clipped');
            }
        },
        watch: {
            active(value) {
                this.is_active = value;
            },
            is_active() {
                this.handleScroll();
            }
        },
        methods: {
            close() {
                this.$emit('update:active', false);
                this.$emit('closed');
                this.is_active = false;
            },
            keyPress(event) {
                // Esc key
                if (this.is_active && event.keyCode === 27) {
                    this.close();
                }
            },
            handleScroll() {
                if (typeof window === 'undefined') {
                    return;
                }
                if (this.is_active) {
                    document.documentElement.classList.add('clipped');
                    // Reset modal scroll to top when opening
                    document.getElementsByClassName('v-modal')[0].scrollTop = 0;
                } else {
                    document.documentElement.classList.remove('clipped');
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../sass/config";

    .v-modal {
        align-items: center;
        background-color: rgba(0, 46, 85, .8);
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        opacity: 0;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 25px 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .15s linear, z-index .15s linear;
        z-index: -1;

        @media (min-width: 990px) {
            padding: 30px 0;
        }

        &--active {
            opacity: 1;
            z-index: 10000;

            .v-modal__content {
                transform: translate(0, 0);
            }
        }

        &__content {
            background-color: $beige;
            margin: 0 auto 40px;
            position: relative;
            transform: translate(0, -25%);
            transition: transform .3s ease-out;
            width: auto;
            z-index: 10100;

            &-wrap {
                @media (min-width: 990px) {
                    width: 900px;
                }
            }
        }

        &__close {
            margin: 0;
            position: absolute;
            right: 0;
        }
    }
</style>