var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pager", attrs: { id: "pager" } }, [
    _vm.pages.length > 1
      ? _c(
          "nav",
          { staticClass: "pagination" },
          [
            _vm.current_page !== 1
              ? _c(
                  "a",
                  {
                    staticClass: "pagination__previous",
                    on: { click: _vm.previousPage }
                  },
                  [_vm._v("←")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function(page) {
              return _c("a", {
                class: { pagination__active: page === _vm.current_page },
                domProps: { innerHTML: _vm._s(page) },
                on: {
                  click: function($event) {
                    return _vm.setPage(page)
                  }
                }
              })
            }),
            _vm._v(" "),
            _vm.current_page !== _vm.maxPages
              ? _c(
                  "a",
                  {
                    staticClass: "pagination__next",
                    on: { click: _vm.nextPage }
                  },
                  [_vm._v("→")]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }