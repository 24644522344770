import axios from 'axios';
import Vue from 'vue';
import Buefy from 'buefy';
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import CuratedSearch from '../vue/CuratedSearch';
import ResultBlock from '../vue/ResultBlock';
import Pagination from '../vue/Pagination';
import ItineraryToolkits from '../vue/ItineraryToolkits';
import MultiStateInspirations from '../vue/MultiStateInspirations';
import TriviaAndFacts from '../vue/TiviaAndFacts';
import ImageUploader from '../vue/ImageUploader';
import Modal from '../vue/Modal';

// VueRecaptcha might have already been imported in shopping-cart, check it
const VueRecaptcha = window.vueRecaptcha || require('../../public/js/vue-recaptcha').default;

window.emailParser = require('../../public/js/address-rfc2822');

const routes = require('../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

Vue.use(Buefy);
Vue.component('curated-search', CuratedSearch);
Vue.component('result-block', ResultBlock);
Vue.component('pagination', Pagination);
Vue.component('itinerary-toolkits', ItineraryToolkits);
Vue.component('multi-state-inspirations', MultiStateInspirations);
Vue.component('trivia-facts', TriviaAndFacts);
Vue.component('image-uploader', ImageUploader);
Vue.component('vue-recaptcha', VueRecaptcha);
Vue.component('modal', Modal);

window.Vue = Vue;
window.path = Routing;
window.vueRecaptcha = VueRecaptcha;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.prototype.$http = axios;
Vue.prototype.$routing = Routing;

document.addEventListener('DOMContentLoaded', function () {
    // Placeholder for any Javascript to be ran after page load
});
