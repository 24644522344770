var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "search-result" }, [
    _c("div", { staticClass: "search-result__image" }, [
      _vm.item.photo
        ? _c("a", { key: "item-photo", attrs: { href: _vm.item.url } }, [
            _c("img", { attrs: { src: _vm.item.photo } })
          ])
        : _c("img", {
            key: "item-photo",
            attrs: { src: "/images/shell/layout/no-listing390x215.jpg" }
          })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "search-result__details" }, [
      _c("h2", { staticClass: "search-result__name" }, [
        _c("a", {
          attrs: { href: _vm.item.url },
          domProps: { innerHTML: _vm._s(_vm.item.title) }
        })
      ]),
      _vm._v(" "),
      _vm.item.deal_name
        ? _c("div", { staticClass: "search-result__deal-name" }, [
            _c("a", {
              attrs: { href: _vm.item.url },
              domProps: { innerHTML: _vm._s(_vm.item.deal_name) }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.event_date
        ? _c("div", {
            staticClass: "search-result__event-date",
            domProps: { innerHTML: _vm._s(_vm.item.event_date) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.event_schedule
        ? _c("div", {
            staticClass: "search-result__event-date",
            domProps: { innerHTML: _vm._s(_vm.item.event_schedule) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.street && _vm.item.city && _vm.item.state && _vm.item.zip
        ? _c("address", [
            _c("div", { staticClass: "result-block__address" }, [
              _vm._v(_vm._s(_vm.item.street)),
              _c("br"),
              _vm._v(
                _vm._s(_vm.item.city) +
                  ", " +
                  _vm._s(_vm.item.state) +
                  " " +
                  _vm._s(_vm.item.zip)
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.item.intro) } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button button-primary button-shadow",
          attrs: { href: _vm.item.url }
        },
        [_vm._v("Learn More")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }