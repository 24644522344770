<template>
    <div class="box">
        <h2>{{ curated_type }}: {{ items.length }}</h2>
        <div v-if="search_items.length > 5" class="curated-search-filter">
            <div class="curated-search-filter__sort">
                <label class="curated-search-filter__sort-label" for="curateSort">Sort</label>
                <select id="curateSort" v-model="current_sort" class="curated-search-filter__sort-list" @change="sortSelected($event)">
                    <option v-for="sortType in sort_types" :value="sortType" v-html="sortType"></option>
                </select>
            </div>
            <div class="curated-search-filter__search">
                <label class="curated-search-filter__search-label" for="query" v-html="'Search ' + curated_type"></label>
                <div class="curated-search-filter__search-fields">
                    <input id="query" v-model="query" :placeholder="'Search ' + curated_type" class="curated-search-filter__search-field" maxlength="100" name="query" type="search" @keydown.enter.prevent="search" />
                    <button class="button is-primary curated-search-filter__submit" type="button" @click="search">
                        <span class="icon"><i class="fas fa-search"></i></span>
                        <span>Search</span>
                    </button>
                </div>
            </div>
        </div>
        <pagination :paginated_items="items" :per_page="items_per_page" :starting_page="current_page" @update-pages="updateCurrentPage" @update-paged-items="getPaginatedItems"></pagination>
        <result-block v-for="item in pagedItems" :key="item.id" :item="item"></result-block>
        <h3 v-if="items.length === 0">No results found. Please change your search and try again.</h3>
        <pagination v-if="items.length > items_per_page" :paginated_items="items" :per_page="items_per_page" :starting_page="current_page" @update-pages="updateCurrentPage"></pagination>
    </div>
</template>

<script>
    export default {
        name: 'CuratedSearch',
        props: {
            search_items: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            curated_type: {
                type: String,
                default: function () {
                    return 'Attractions';
                }
            },
            items_per_page: {
                type: Number,
                default: function () {
                    return 10;
                }
            }
        },
        data() {
            return {
                query: '',
                items: [],
                pagedItems: [],
                sort_types: ['Default', 'Alphabetical', 'City', 'Relevance'],
                current_sort: 'Default',
                current_page: 1
            };
        },
        mounted: function () {
            for (let item in this.search_items) {
                this.items.push(this.search_items[item]);
            }
            if (this.curated_type === 'Events') {
                this.sort_types = ['Default', 'Alphabetical', 'City', 'Date', 'Relevance'];
            }
        },
        methods: {
            sortSelected: function (event) {
                this.search(event, true);
            },
            search: function (event, forceSort) {
                let query = this.query;
                // Prepare item array for more items
                this.items = [];

                if (query !== '' && query.length > 1) {
                    this.current_page = 1;
                    let weightedItems = [];
                    let itemMatches;
                    let weight = 0;
                    // Get a set of items that match the search, creating weighted items
                    for (let search_item of this.search_items) {
                        itemMatches = search_item.title.match(new RegExp(query, 'gi'));
                        if (itemMatches && itemMatches.length) {
                            weight += itemMatches.length;
                        }

                        if (search_item.related_tags) {
                            for (let related_tag of search_item.related_tags) {
                                itemMatches = related_tag[tagName].match(new RegExp(query, 'gi'));
                                if (itemMatches && itemMatches.length) {
                                    weight += itemMatches.length * .7;
                                }
                            }
                        }

                        if (search_item.description) {
                            itemMatches = search_item.description.match(new RegExp(query, 'gi'));
                            if (itemMatches && itemMatches.length) {
                                weight += itemMatches.length * .5;
                            }
                        }

                        if (search_item.city) {
                            itemMatches = search_item.city.match(new RegExp(query, 'gi'));
                            if (itemMatches && itemMatches.length) {
                                weight += itemMatches.length * .3;
                            }
                        }

                        if (search_item.street) {
                            itemMatches = search_item.street.match(new RegExp(query, 'gi'));
                            if (itemMatches && itemMatches.length) {
                                weight += itemMatches.length * .1;
                            }
                        }

                        if (weight > 0) {
                            weightedItems.push({
                                'item': search_item,
                                'weight': weight
                            });
                        }
                        weight = 0;
                    }
                    // If sort was select by the user, use that instead
                    if ((!forceSort && weightedItems.length > 0) || (forceSort && this.current_sort === 'Relevance')) {
                        // Sort weighted items to show most relevant items first
                        weightedItems.sort((a, b) => (b.weight > a.weight) ? 1 : (a.weight === b.weight) ? ((a.name > b.name) ? 1 : -1) : -1);
                        this.current_sort = 'Relevance';
                    }
                    for (let weightedItem of weightedItems) {
                        this.items.push(weightedItem.item);
                    }

                } else {
                    for (let search_item in this.search_items) {
                        this.items.push(search_item);
                    }
                }

                this.sort();
            },
            sort: function () {
                if (this.current_sort === 'Alphabetical') {
                    this.items.sort((a, b) => (a.title > b.title) ? 1 : -1);
                }
                if (this.current_sort === 'City') {
                    this.items.sort((a, b) => (a.city > b.city) ? 1 : -1);
                }
                if (this.current_sort === 'Date') {
                    this.items.sort((a, b) => (a.start_date > b.start_date) ? 1 : -1);
                }
            },
            updateCurrentPage: function (currentPage) {
                this.current_page = currentPage;
            },
            getPaginatedItems: function (paginatedItems) {
                this.pagedItems = paginatedItems;
            }
        }
    };
</script>