var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      { staticClass: "form-item__label", class: { required: _vm.required } },
      [
        _vm._v(
          _vm._s(
            _vm.title ? _vm.title : "Click to upload or drop your images here"
          )
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "image-upload__max" }, [
      _c("p", [_vm._v("Maximum files: " + _vm._s(_vm.max))]),
      _vm._v(" "),
      _vm.dimensions
        ? _c("p", [_vm._v("Required Dimensions: " + _vm._s(_vm.dimensions))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "image-upload__container",
        on: {
          dragover: function($event) {
            $event.preventDefault()
            return _vm.dragOver.apply(null, arguments)
          },
          dragleave: function($event) {
            $event.preventDefault()
            return _vm.dragLeave.apply(null, arguments)
          },
          drop: function($event) {
            $event.preventDefault()
            return _vm.drop($event)
          }
        }
      },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dropping === 1,
              expression: "dropping === 1"
            }
          ],
          staticClass: "image-upload__drop"
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error"
              }
            ],
            staticClass: "image-upload__error"
          },
          [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.images.length === 0,
                expression: "images.length === 0"
              }
            ],
            staticClass: "image-upload__before"
          },
          [
            _c("input", {
              ref: "uploadInput",
              attrs: {
                accept: _vm.allowedFileTypes,
                name: _vm.inputName,
                multiple: "",
                tabindex: "-1",
                type: "file"
              }
            }),
            _vm._v(" "),
            _c("input", {
              ref: "uploadInputBuffer",
              attrs: {
                accept: _vm.allowedFileTypes,
                multiple: "",
                type: "file"
              },
              on: { change: _vm.imageInputChanged }
            }),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "image-upload__icon",
                attrs: {
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("title", [_vm._v("Upload Image")]),
                _vm._v(" "),
                _c("g", [
                  _c("g", [
                    _c("g", [
                      _c("g", [
                        _c("circle", {
                          attrs: { cx: "18.5", cy: "16.5", r: "5" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("polyline", {
                        attrs: { points: "16.5 15.5 18.5 13.5 20.5 15.5" }
                      }),
                      _vm._v(" "),
                      _c("line", {
                        attrs: {
                          x1: "18.5",
                          x2: "18.5",
                          y1: "13.5",
                          y2: "19.5"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("g", [
                      _c("polyline", {
                        attrs: { points: "0.6 15.42 6 10.02 8.98 13" }
                      }),
                      _vm._v(" "),
                      _c("polyline", {
                        attrs: { points: "17.16 11.68 12.5 7.02 7.77 11.79" }
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: { cx: "8", cy: "6.02", r: "1.5" }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "M19.5,11.6V4A1.5,1.5,0,0,0,18,2.5H2A1.5,1.5,0,0,0,.5,4V15A1.5,1.5,0,0,0,2,16.5H13.5"
                        }
                      })
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("p", [_vm._v("Drag files or click here!")])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.images.length > 0,
                expression: "images.length > 0"
              }
            ],
            staticClass: "image-upload__preview"
          },
          [
            _c(
              "button",
              {
                staticClass: "image-upload__clear",
                attrs: { type: "button" },
                on: { click: _vm.reset }
              },
              [
                _vm._v(
                  _vm._s(_vm.clearAllText ? _vm.clearAllText : "Clear All")
                )
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.images, function(image, i) {
              return _c(
                "div",
                { key: i, staticClass: "image-upload__image-holder" },
                [
                  _c("img", {
                    attrs: {
                      alt: image.name,
                      src: image.data,
                      title: image.name
                    }
                  }),
                  _vm._v(" "),
                  ++i === _vm.images.length && _vm.images.length < _vm.max
                    ? _c(
                        "div",
                        {
                          staticClass: "image-upload__plus",
                          on: { click: _vm.append }
                        },
                        [_vm._v("+")]
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }